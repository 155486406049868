import m from 'mithril';
import { icon } from '../../utils/html';
import { inputText, roundIconButton } from '../../utils/html';
import { EsdlStoreSvc } from '../../services/esdl-item-service';
import { ItemCard } from './item-card';

export const EsdlItemList = () => {
  const state = {
    skip: 0,
    take: 12,
    searchValue: '',
  };

  const search = (query: string) => {
    if (query.length < 3) {
      EsdlStoreSvc.clearSearchResults();
      return;
    }
    EsdlStoreSvc.search(query).catch(e => console.error(e));
  };

  const showSearchResult = () =>
    state.searchValue && state.searchValue.length >= 3;

  const nextPrev = (direction: 'prev' | 'next') => {
    switch (direction) {
      case 'prev':
        state.skip -= state.take;
        if (state.skip < 0) {
          state.skip = 0;
        }
        break;
      case 'next':
        state.skip += state.take;
        break;
    }
    EsdlStoreSvc.loadList(state.skip, state.take);
  };

  return {
    oninit: () => EsdlStoreSvc.loadList(state.skip, state.take),
    view: () => {
      const esdlItems = showSearchResult()
        ? EsdlStoreSvc.getSearchResult()
        : EsdlStoreSvc.getList();
      return m('.row', [
        m('.row', [
          roundIconButton({
            iconName: 'add',
            ui: {
              class: 'green input-field right',
              href: '/new',
              oncreate: m.route.link,
              onclick: () => {
                EsdlStoreSvc.new();
              },
            },
          }),
          inputText({
            label: 'Search',
            id: 'search',
            iconName: 'search',
            initialValue: state.searchValue,
            onchange: (v: string) => {
              state.searchValue = v;
              search(v);
            },
            style: 'margin-right:100px',
            classNames: 'right',
          }),
        ]),
        m('.row', esdlItems.map(item => m(ItemCard, { item }))),
        // Pagination component
        m(
          'ul.pagination',
          { style: 'position: absolute; bottom: 0; right: 0' },
          [
            m(
              'li.waves-effect.right',
              { class: esdlItems.length >= state.take ? '' : 'disabled' },
              m(
                'a[href=#!]',
                { onclick: () => nextPrev('next') },
                icon('chevron_right')
              )
            ),
            m(
              'li.waves-effect.right',
              { class: state.skip > 0 ? '' : 'disabled' },
              m(
                'a[href=#!]',
                { onclick: () => nextPrev('prev') },
                icon('chevron_left')
              )
            ),
          ]
        ),
      ]);
    },
  };
};
