import m, { RouteDefs, ComponentTypes } from 'mithril';
import { IDashboard } from '../models/dashboard';
import { ISubscriptionDefinition } from './message-bus-service';
import { TopicNames, esdlChannel } from '../models/channels';
import { Layout } from '../components/layout';
import { EsdlItemList } from '../components/item/item-list';
import { EsdlItemView } from '../components/item/item-view';

export const enum Dashboards {
  HOME = 'HOME',
  NEW_ITEM = 'NEW_ITEM',
  ITEM = 'ITEM',
}

class DashboardService {
  private subscription!: ISubscriptionDefinition<any>;
  private dashboards!: ReadonlyArray<IDashboard>;

  constructor(private layout: ComponentTypes, dashboards: IDashboard[]) {
    this.setList(dashboards);
    this.subscribe();
  }

  public getList() {
    return this.dashboards;
  }

  public setList(list: IDashboard[]) {
    this.dashboards = Object.freeze(list);
  }

  public get defaultRoute() {
    const dashboard = this.dashboards.filter(d => d.default).shift();
    return dashboard ? dashboard.route : this.dashboards[0].route;
  }

  public get routingTable() {
    return this.dashboards.reduce(
      (p, c) => {
        p[c.route] = { render: () => m(this.layout, m(c.component)) };
        return p;
      },
      {} as RouteDefs
    );
  }

  public switchTo(dashboardId: Dashboards, fragment = '') {
    const dashboard = this.dashboards.filter(d => d.id === dashboardId).shift();
    if (dashboard) {
      m.route.set(dashboard.route);
    }
  }

  private subscribe() {
    this.subscription = esdlChannel.subscribe(TopicNames.ITEM_UPDATE, ({ cur }) => {
      if (cur) {
        this.setList(
          this.dashboards.map(d => {
            d.visible = d.id !== Dashboards.NEW_ITEM;
            return d;
          })
        );
        this.switchTo(Dashboards.ITEM, `/${cur.id}`);
      } else {
        this.setList(
          this.dashboards.map(d => {
            d.visible = d.id === Dashboards.HOME;
            return d;
          })
        );
        this.switchTo(Dashboards.HOME);
      }
    });
  }
}

export const dashboardSvc: DashboardService = new DashboardService(Layout, [
  {
    id: Dashboards.HOME,
    default: true,
    title: 'Home',
    route: '/home',
    visible: true,
    component: EsdlItemList,
  },
  {
    id: Dashboards.ITEM,
    title: 'Store',
    route: '/store',
    visible: false,
    component: EsdlItemView,
  },
]);
