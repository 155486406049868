import { RestService } from './rest-service';
import { ChannelNames } from '../models/channels';
import { IEsdlItem } from '../models/esdl-item';

class EsdlStoreService extends RestService<IEsdlItem> {
  constructor() {
    super('store', ChannelNames.ESDLITEM);
  }
}

export const EsdlStoreSvc = new EsdlStoreService();
