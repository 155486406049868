import m, { Component, Vnode } from 'mithril';
import { xml2js, Element, Attributes, DeclarationAttributes } from 'xml-js';
import { TreeContainer, ITreeOptions, ITreeItem, uuid4, ITreeItemViewComponent } from 'mithril-tree-component';
import { EsdlStoreSvc } from '../../services/esdl-item-service';
import { esdlChannel, TopicNames } from '../../models/channels';
import { ISubscriptionDefinition } from '../../services/message-bus-service';
import { IEsdlItem } from '../../models/esdl-item';

enum TreeItemType {
  ATTRIBUTE = 'ATTRIBUTE',
  ELEMENT = 'ELEMENT',
}

interface IMyTreeItem extends ITreeItem {
  id: string;
  name: string;
  value: string | number | boolean;
  type: TreeItemType;
  isOpen?: boolean;
  parentId?: string;
  children?: IMyTreeItem[];
}

export const TreeView = () => {
  const state = {
    tree: undefined as IMyTreeItem[] | undefined,
    subscription: undefined as ISubscriptionDefinition<{ cur: IEsdlItem; old: IEsdlItem }> | undefined,
  };

  const options = {
    id: 'id',
    parentId: 'parentId',
    isOpen: 'isOpen',
    treeItemView: {
      view: ({ attrs }: Vnode<ITreeItemViewComponent>) => {
        const ti = attrs.treeItem;
        const name = ti.name;
        const childName = ti.children
          ? ti.children
              .filter((c: IMyTreeItem) => c.name === 'name')
              .map((c: IMyTreeItem) => c.value)
              .shift()
          : '';
        const value = ti.value;
        const childValue = ti.children
          ? ti.children
              .filter((c: IMyTreeItem) => !c.name || c.name === 'value')
              .map((c: IMyTreeItem) => c.value)
              .shift()
          : '';
        return m('div', { style: 'display: inline-block' }, [
          m('span', name || childName),
          m(
            'span',
            { style: 'font-weight: bold; margin-left: 10px;' },
            value ? value : name && childName && childValue ? `${childName} = ${childValue}` : childName || childValue
          ),
        ]);
      },
    } as Component<ITreeItemViewComponent>,
    name: 'name',
    onSelect: (ti, isSelected) => console.log(`On ${isSelected ? 'select' : 'unselect'}: ${ti.name}`),
    editable: { canCreate: false, canDelete: false, canUpdate: false, canDeleteParent: false },
  } as ITreeOptions;

  const attributes2tree = (parentId: string, attributes?: Attributes) =>
    attributes
      ? Object.keys(attributes).map(
          name =>
            ({
              id: uuid4(),
              name,
              value: attributes[name],
              parentId,
              type: TreeItemType.ATTRIBUTE,
            } as IMyTreeItem)
        )
      : ([] as IMyTreeItem[]);

  const element2tree = (parentId: string, e: Element) => {
    const id = uuid4();
    return {
      id,
      name: e.name,
      value: e.text,
      type: TreeItemType.ELEMENT,
      parentId,
      children: [...attributes2tree(id, e.attributes), ...elements2tree(id, e.elements)],
    } as IMyTreeItem;
  };

  const elements2tree = (parentId: string, elements?: Element[]): IMyTreeItem[] =>
    (elements ? elements.map(e => element2tree(parentId, e)) : []) as IMyTreeItem[];

  const xmlRootElement = (x: Element) => {
    const id = uuid4();
    return {
      id,
      isOpen: true,
      name: x.name || 'XML',
      children: x.declaration ? attributes2tree(id, x.declaration.attributes as Attributes) : [],
    } as IMyTreeItem;
  };

  const xml2tree = (x: Element) => {
    const root = xmlRootElement(x);
    if (x.elements) {
      root.children = x.elements.map(e => element2tree(root.id, e));
      if (root.children && root.children.length > 0) {
        root.children[0].isOpen = true;
      }
      return [root] as IMyTreeItem[];
    }
  };

  const esdlItem2tree = (item?: IEsdlItem) => {
    if (item && item.esdl) {
      const xml = xml2js(item.esdl, { compact: false, trim: true }) as Element;
      state.tree = xml2tree(xml);
    } else {
      state.tree = undefined;
    }
  };

  return {
    oninit: () => {
      state.subscription = esdlChannel.subscribe(TopicNames.ITEM, ({ cur }) => esdlItem2tree(cur));
      esdlItem2tree(EsdlStoreSvc.getCurrent());
    },
    onremove: () => (state.subscription ? state.subscription.unsubscribe() : ''),
    view: () => {
      const tree = state.tree;
      return tree ? m(TreeContainer, { tree, options }) : m('div', 'No ESDL loaded');
      // return m('div', item && item.esdl ? JSON.stringify(xml, null, 2) : 'No ESDL loaded');
    },
  } as Component;
};
