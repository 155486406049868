import m from 'mithril';
import { EsdlItemForm } from './item-form';
import { TreeView } from '../xml/tree-view';

export const EsdlItemView = () => ({
  view: () => m('.row', [
    m('.col s5', m(EsdlItemForm)),
    m('.col s7', m(TreeView)),
  ]),
});
