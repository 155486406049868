import m, { Vnode } from 'mithril';
import { IEsdlItem } from '../../models/esdl-item';
import { EsdlStoreSvc } from '../../services/esdl-item-service';
import { chips } from '../../utils/html';

export const ItemCard = {
  view: ({ attrs }: Vnode<{ item: IEsdlItem }>) => {
    const item = attrs.item;
    return m('.col.s6.m4.l3', [
      m(
        '.card.small',
        m('.card-content', [
          m('span.card-title', item.title),
          m('p', item.description),
          m('p', chips('', item.tags)),
          m('p.right-align.grey-text.owner', `${item.email}, v${item.version}`),
          m(
            '.card-action',
            m(
              'a[href=#]',
              {
                onclick: () => EsdlStoreSvc.load(item.id),
              },
              'Open'
            )
          ),
        ])
      ),
    ]);
  },
};
