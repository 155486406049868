import m from 'mithril';
import {
  inputTextArea,
  inputText,
  button,
  inputEmail,
  roundIconButton,
  chips,
  initChips
} from '../../utils/html';
import { EsdlStoreSvc } from '../../services/esdl-item-service';
import { IEsdlItem } from '../../models/esdl-item';
import { deepCopy, deepEqual } from '../../utils/utils';

const log = console.log;
const close = async (e: UIEvent) => {
  log('closing...');
  await EsdlStoreSvc.unload();
  m.route.set('/');
  e.preventDefault();
};

const stopPropagation = (e: UIEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

export const EsdlItemForm = () => {
  const state = {
    original: undefined as IEsdlItem | undefined,
    esdlItem: {} as IEsdlItem,
    esdlFile: undefined as File | undefined,
  };

  const handleFiles = (files: FileList | null) => {
    const file = files && files.length > 0 ? files[0] : undefined;
    if (file && file.name) {
      state.esdlFile = file;
    }
  };

  const onsubmit = async (e: MouseEvent) => {
    e.preventDefault();
    log('saving...');
    const esdlItem = state.esdlItem;
    const esdlFile = state.esdlFile;
    if (esdlItem) {
      if (esdlFile) {
        const reader = new FileReader();
        reader.onerror = (ev: ProgressEvent) => {
          console.error(ev);
          reader.abort();
        };
        reader.onload = async (ev: ProgressEvent) => {
          if (ev.target) {
            esdlItem.esdl = (ev.target as any).result;
            const result = (await EsdlStoreSvc.save(esdlItem)) as IEsdlItem;
            if (result) {
              state.esdlItem = deepCopy(result);
              state.original = deepCopy(result);
            } else {
              console.error('Error saving ESDL to the database');
            }
            m.redraw();
          }
        };
        reader.readAsText(esdlFile);
      } else {
        const result = (await EsdlStoreSvc.save(esdlItem)) as IEsdlItem;
        state.esdlItem = deepCopy(result);
        state.original = deepCopy(result);
      }
    }
  };

  const mruTags = (initialTags: string[] = []) => {
    const list = EsdlStoreSvc.getList();
    if (!list) { return; }
    return list.reduce((p, c) => {
      if (!c.tags) { return p; }
      return c.tags.reduce((a, i) => p.indexOf(i) < 0 ? [...p, i] : p, p);
    }, initialTags);
  };

  return {
    oninit: () => {
      log('On INIT');
      log(state);
      const esdlItem = EsdlStoreSvc.getCurrent();
      if (!esdlItem || !esdlItem.id) {
        log('On INIT: NEW');
        state.esdlItem = deepCopy(EsdlStoreSvc.new());
      } else {
        state.esdlItem = deepCopy(esdlItem);
      }
      state.original = deepCopy(state.esdlItem);
    },
    oncreate: () => {
      initChips(
        state.esdlItem.tags,
        mruTags(['profile', 'energy', 'price', 'asset', 'heatpump', 'WKK']),
        tags => {
          state.esdlItem.tags = tags;
          m.redraw();
        }
      );
    },
    view: () => {
      const esdlItem = state.esdlItem;
      const hasChanged = !deepEqual(esdlItem, state.original);
      const canDelete = esdlItem.id && esdlItem.id.length > 0;
      const canSave =
        hasChanged &&
        esdlItem.title &&
        esdlItem.description &&
        esdlItem.email &&
        (esdlItem.esdl || state.esdlFile);
      return m(
        '.row',
        { style: 'color: black' },
        m('form.col.s12', { onsubmit }, [
          m('.row', [
            [
              esdlItem && esdlItem.id
                ? inputText({
                    id: 'id',
                    initialValue: esdlItem.id,
                    onchange: (v: string) => (esdlItem.id = v),
                    label: 'Id',
                    disabled: true,
                    iconName: 'perm_identity',
                  })
                : '',
              inputText({
                id: 'title',
                initialValue: esdlItem.title,
                onchange: (v: string) => (esdlItem.title = v),
                label: 'Title *',
                iconName: 'title',
              }),
              inputEmail({
                id: 'email',
                initialValue: esdlItem.email,
                onchange: (v: string) => (esdlItem.email = v),
                label: 'Email *',
                iconName: 'email',
              }),
              chips('label'),
              inputTextArea({
                id: 'desc',
                initialValue: esdlItem.description,
                onchange: (v: string) => (esdlItem.description = v),
                label: 'Description *',
                iconName: 'description',
              }),
              roundIconButton({
                iconName: 'cloud_upload',
                ui: {
                  onclick: (e: UIEvent) => {
                    e.preventDefault();
                    const fileElem = document.getElementById('esdlfile');
                    if (fileElem) {
                      fileElem.click();
                    }
                  },
                },
              }),
              m(
                'div.drop-area',
                {
                  class: state.esdlFile ? 'valid' : '',
                  ondragover: stopPropagation,
                  ondragenter: stopPropagation,
                  ondrop: (e: DragEvent) => {
                    stopPropagation(e);
                    const dt = e.dataTransfer;
                    if (dt) { handleFiles(dt.files); }
                  },
                },
                state.esdlFile
                  ? `${state.esdlFile.name}\n(${state.esdlFile.size} bytes)`
                  : 'Drop ESDL here'
              ),
              m(
                'input[id=esdlfile][type=file][multiple=false][accept=application/xml,.xmi,.esdl]',
                {
                  style: 'display: none;',
                  onchange: (e: UIEvent) => {
                    const files = (e.srcElement as HTMLInputElement).files;
                    handleFiles(files);
                  },
                }
              ),
            ],
          ]),
          m('row', [
            button({
              iconName: 'undo',
              ui: {
                class: `green ${hasChanged ? '' : 'disabled'}`,
                onclick: () =>
                  (state.esdlItem = deepCopy(state.original) as IEsdlItem),
              },
            }),
            ' ',
            button({
              iconName: 'save',
              ui: {
                class: `green ${canSave ? '' : 'disabled'}`,
                type: 'submit',
              },
            }),
            ' ',
            button({
              iconName: 'close',
              ui: {
                onclick: e => close(e),
              },
            }),
            ' ',
            button({
              iconName: 'delete',
              ui: {
                class: `red ${canDelete ? '' : 'disabled'}`,
                onclick: e => {
                  EsdlStoreSvc.delete(esdlItem.id);
                  close(e);
                },
              },
            }),
          ]),
        ])
      );
    },
  };
};
