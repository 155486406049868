import { messageBus } from '../services/message-bus-service';
import { IEsdlItem } from './esdl-item';

export const ChannelNames = {
  DEFAULT_CHANNEL: 'DEFAULT_CHANNEL',
  ESDLITEM: 'ESDLITEM',
};

export const TopicNames = {
  ALL: '#',
  ITEM: 'ITEM',
  ITEM_SELECT: 'ITEM.SELECT',
  ITEM_CREATE: 'ITEM.CREATE',
  ITEM_DELETE: 'ITEM.DELETE',
  ITEM_UPDATE: 'ITEM.UPDATE',
  LIST: 'LIST',
  LIST_CREATE: 'LIST.CREATE',
  LIST_DELETE: 'LIST.DELETE',
  LIST_UPDATE: 'LIST.UPDATE',
};

export const esdlChannel = messageBus.channel<{ cur: IEsdlItem; old?: IEsdlItem }>(
  ChannelNames.ESDLITEM
);
